@charset "UTF-8";
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
  -webkit-text-size-adjust: none;
}

body {
  background-color: #f5f5f5;
  min-width: 1280px;
  overflow: auto;
  min-height: 800px;
}

* {
  padding: 0;
  margin: 0;
  font-family: "Pretendard Variable", Pretendard, sans-serif;
  font-style: normal;
}

body, div, p, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, table, th, td {
  color: #000;
}

img {
  border: none;
  user-select: none;
}

ul, ol {
  list-style: none;
}

em {
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover, a:active, a:focus {
  text-decoration: none;
}

input, button, button:focus, input:focus {
  outline: none;
  border: none;
  background: none;
}

table, td, th {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  padding: 0;
}

textarea {
  letter-spacing: 0;
  outline: none;
  overflow: auto;
  resize: none;
}

.clear {
  clear: both;
  zoom: 1;
}

.clear:after {
  content: "";
  display: block;
  clear: both;
}

#wrap2024 {
  padding: 30px;
  display: grid;
  gap: 20px;
  grid-template-columns: 300px calc(100% - 320px);
}

#ScopeLNBWrap {
  height: calc(100vh - 60px);
  position: sticky;
  top: 30px;
  left: 30px;
  min-height: 800px;
}
#ScopeLNBWrap #ScopeLNB {
  box-sizing: border-box;
  width: 300px;
  height: 100%;
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #919191;
  position: relative;
  overflow: hidden;
}
#ScopeLNBWrap #ScopeLNB h1 {
  height: 74px;
  display: flex;
  align-items: center;
  padding: 0 27px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 12px;
}
#ScopeLNBWrap #ScopeLNB h1 strong {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.36px;
  background: url("../images/logo_things.svg") no-repeat 0 0;
  display: block;
  padding-top: 18px;
}
#ScopeLNBWrap #ScopeLNB p {
  padding: 0 16px;
  margin-bottom: 10px;
}
#ScopeLNBWrap #ScopeLNB p.line {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #ccc;
}
#ScopeLNBWrap #ScopeLNB p.accountWrap {
  padding: 12px 16px;
  margin-bottom: 0;
  border-top: 1px solid #ccc;
}
#ScopeLNBWrap #ScopeLNB p.accountWrap.on {
  background-color: #000;
}
#ScopeLNBWrap #ScopeLNB p.accountWrap.on em {
  color: #fff;
}
#ScopeLNBWrap #ScopeLNB p.accountWrap .account b {
  background: url("../images/lnb/account_on.svg") no-repeat 50% 50% #FB7818;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-indent: -999em;
}
#ScopeLNBWrap #ScopeLNB p > a, #ScopeLNBWrap #ScopeLNB p > span {
  height: 46px;
  display: flex;
  align-items: center;
  padding-right: 12px;
  gap: 16px;
  border-radius: 6px;
  cursor: pointer;
}
#ScopeLNBWrap #ScopeLNB p > a b, #ScopeLNBWrap #ScopeLNB p > span b {
  width: 46px;
  height: 46px;
  border-radius: 6px;
  background-repeat: no-repeat;
  background-position: 0 0;
  box-sizing: border-box;
}
#ScopeLNBWrap #ScopeLNB p > a em, #ScopeLNBWrap #ScopeLNB p > span em {
  font-size: 16px;
  font-weight: 700;
  background: url("../images/lnb/lnb_arrow.svg") no-repeat 100% 50%;
  padding-right: 23px;
  display: block;
  width: calc(100% - 62px);
  box-sizing: border-box;
  overflow: hidden;
}
#ScopeLNBWrap #ScopeLNB p > a.home b, #ScopeLNBWrap #ScopeLNB p > span.home b {
  background-image: url("../images/lnb/home.svg");
}
#ScopeLNBWrap #ScopeLNB p > a.produc b, #ScopeLNBWrap #ScopeLNB p > span.produc b {
  background-image: url("../images/lnb/product.svg");
}
#ScopeLNBWrap #ScopeLNB p > a.pricing b, #ScopeLNBWrap #ScopeLNB p > span.pricing b {
  background-image: url("../images/lnb/price.svg");
}
#ScopeLNBWrap #ScopeLNB p > a.find b, #ScopeLNBWrap #ScopeLNB p > span.find b {
  background-image: url("../images/lnb/search.svg");
}
#ScopeLNBWrap #ScopeLNB p > a.develop b, #ScopeLNBWrap #ScopeLNB p > span.develop b {
  background-image: url("../images/lnb/develop.svg");
}
#ScopeLNBWrap #ScopeLNB p > a.setting b, #ScopeLNBWrap #ScopeLNB p > span.setting b {
  background-image: url("../images/lnb/setting.svg");
}
#ScopeLNBWrap #ScopeLNB p > a.help b, #ScopeLNBWrap #ScopeLNB p > span.help b {
  background-image: url("../images/lnb/Help.svg");
}
#ScopeLNBWrap #ScopeLNB p > a.notifi, #ScopeLNBWrap #ScopeLNB p > span.notifi {
  position: relative;
}
#ScopeLNBWrap #ScopeLNB p > a.notifi b, #ScopeLNBWrap #ScopeLNB p > span.notifi b {
  background-image: url("../images/lnb/alarm.svg");
}
#ScopeLNBWrap #ScopeLNB p > a.notifi u.new, #ScopeLNBWrap #ScopeLNB p > span.notifi u.new {
  width: 10px;
  height: 10px;
  background-color: #f00;
  border-radius: 50px;
  position: absolute;
  top: 6px;
  left: 30px;
}
#ScopeLNBWrap #ScopeLNB p > a.signin b, #ScopeLNBWrap #ScopeLNB p > span.signin b {
  background-image: url("../images/lnb/account.svg");
  border-radius: 50%;
}
#ScopeLNBWrap #ScopeLNB p.on a[data-link=goTo] {
  background-color: #000;
}
#ScopeLNBWrap #ScopeLNB p.on a[data-link=goTo] em {
  color: #fff;
}
#ScopeLNBWrap #ScopeLNB p.on a[data-link=subopen] b, #ScopeLNBWrap #ScopeLNB p.on span b {
  background-color: #000;
}
#ScopeLNBWrap #ScopeLNB p.on .home b {
  background-image: url("../images/lnb/home_on.svg");
}
#ScopeLNBWrap #ScopeLNB p.on .produc b {
  background-image: url("../images/lnb/product_on.svg");
}
#ScopeLNBWrap #ScopeLNB p.on .find b {
  background-image: url("../images/lnb/search_on.svg");
}
#ScopeLNBWrap #ScopeLNB p.on .pricing b {
  background-image: url("../images/lnb/price_on.svg");
}
#ScopeLNBWrap #ScopeLNB p.on .develop b {
  background-image: url("../images/lnb/develop_on.svg");
}
#ScopeLNBWrap #ScopeLNB p.on .setting b {
  background-image: url("../images/lnb/setting_on.svg");
}
#ScopeLNBWrap #ScopeLNB p.on .help b {
  background-image: url("../images/lnb/Help_on.svg");
}
#ScopeLNBWrap #ScopeLNB p.on .notifi b {
  background-image: url("../images/lnb/alarm_on.svg");
}
#ScopeLNBWrap #ScopeLNB p.on .signin b {
  background-image: url("../images/lnb/account_on.svg");
}
#ScopeLNBWrap #ScopeLNB p:hover b {
  background-color: #000;
}
#ScopeLNBWrap #ScopeLNB p:hover .home b {
  background-image: url("../images/lnb/home_on.svg");
}
#ScopeLNBWrap #ScopeLNB p:hover .produc b {
  background-image: url("../images/lnb/product_on.svg");
}
#ScopeLNBWrap #ScopeLNB p:hover .find b {
  background-image: url("../images/lnb/search_on.svg");
}
#ScopeLNBWrap #ScopeLNB p:hover .pricing b {
  background-image: url("../images/lnb/price_on.svg");
}
#ScopeLNBWrap #ScopeLNB p:hover .develop b {
  background-image: url("../images/lnb/develop_on.svg");
}
#ScopeLNBWrap #ScopeLNB p:hover .setting b {
  background-image: url("../images/lnb/setting_on.svg");
}
#ScopeLNBWrap #ScopeLNB p:hover .help b {
  background-image: url("../images/lnb/Help_on.svg");
}
#ScopeLNBWrap #ScopeLNB p:hover .notifi b {
  background-image: url("../images/lnb/alarm_on.svg");
}
#ScopeLNBWrap #ScopeLNB p:hover .signin b {
  background-image: url("../images/lnb/account_on.svg");
}
#ScopeLNBWrap #ScopeLNB div.btm {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
#ScopeLNBWrap #ScopeLNB div.btm p.line {
  margin-bottom: 0;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen {
  position: absolute;
  top: 75px;
  background-color: #fff;
  height: calc(100% - 75px);
  width: calc(100% - 75px);
  border-left: 1px solid #ccc;
  box-sizing: border-box;
  right: -223px;
  transition: right 0.3s ease-in;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen.on {
  right: 0;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen strong {
  padding: 12px 0 12px 20px;
  height: 46px;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: left;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen strong span {
  background: url("../images/lnb/lnb_back.svg") no-repeat 0 50%;
  padding-left: 25px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen ul.notiList {
  height: calc(100% - 0px);
  overflow-y: auto;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen ul.notiList::-webkit-scrollbar {
  width: 4px;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen ul.notiList::-webkit-scrollbar-thumb {
  background-color: #eee;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen ul.notiList li {
  border-bottom: 1px solid #ccc;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen ul li b {
  margin: 22px 0 6px 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: left;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen ul li a {
  margin: 4px 14px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  cursor: pointer;
  padding: 5px 12px;
  border: 1px solid #fff;
  border-radius: 6px;
  color: #919191;
  font-weight: 500;
  font-size: 13px;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen ul li a.on {
  border-color: #000;
  background-color: #f5f5f5;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen ul li div {
  padding: 20px;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen ul li div span {
  display: block;
  font-size: 12px;
  color: #919191;
  padding-right: 30px;
  background: url("../images/ic_noti.svg") no-repeat 100% 100%;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen .logout {
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-align: right;
}
#ScopeLNBWrap #ScopeLNB .LNBOpen .logout button {
  padding-right: 32px;
  background: url("../images/logout.svg") no-repeat 100% 50%;
  display: block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 800;
  color: #919191;
  height: 32px;
}

#ScopeWrap .pageTitle {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.48px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
}
#ScopeWrap .pageTitle .btnArea {
  position: absolute;
  top: 0;
  right: 0;
}
#ScopeWrap .pageTitle .btnArea button {
  cursor: pointer;
  border-radius: 6px;
  background-color: #4E75FF;
  height: 44px;
  min-width: 240px;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}
#ScopeWrap .pageTitle .btnArea button span.back {
  background: url("../images/ic_back.svg") no-repeat 0 50%;
  padding-left: 30px;
  display: inline-block;
}
#ScopeWrap .pageTitle button.back {
  background: url("../images/ic_back_black.svg") no-repeat 50% 50%;
  width: 38px;
  cursor: pointer;
  height: 38px;
  margin-right: 20px;
}
#ScopeWrap .cbSpBox {
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #919191;
}
#ScopeWrap .cbSpBox.innerScroll {
  height: calc(100vh - 130px);
  min-height: 800px;
}

.inputObj {
  position: relative;
}
.inputObj label {
  display: block;
  font-size: 16px;
  font-weight: 700;
}
.inputObj > div {
  position: relative;
}
.inputObj > div p {
  border-radius: 6px;
  border: 1px solid #000;
  min-height: 36px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.inputObj > div p b {
  display: block;
  width: calc(100% - 36px);
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
  color: #2b2b2b;
  font-weight: 500;
}
.inputObj > div p b.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.inputObj > div p b.placeholder {
  color: #919191;
}
.inputObj > div p input {
  display: block;
  width: 100%;
  height: 36px;
  color: #2b2b2b;
  font-size: 16px;
  font-weight: 500;
}
.inputObj > div p button {
  width: 36px;
  height: 36px;
  background: url("../images/bi_search.svg") no-repeat 50% 50% #fff;
  border-radius: 50%;
  text-indent: -999em;
  cursor: pointer;
}
.inputObj > div p button:hover {
  background-color: #eee;
}
.inputObj > div ul {
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
  max-height: 280px;
  background-color: #fff;
  overflow-y: auto;
  display: none;
  z-index: 12;
  border-radius: 4px;
  border: 1px solid #d3d3d4;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
}
.inputObj > div ul::-webkit-scrollbar {
  width: 4px;
}
.inputObj > div ul::-webkit-scrollbar-thumb {
  background-color: #eee;
}
.inputObj > div ul li {
  font-size: 14px;
  padding: 15px 20px;
  border-bottom: 1px solid #d3d3d4;
  cursor: pointer;
}
.inputObj > div ul li:last-child {
  border-bottom: none;
}
.inputObj > div ul li:hover {
  color: #4E75FF;
}

.selectObj {
  position: relative;
}
.selectObj p {
  height: 56px;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.selectObj p span {
  display: block;
  border-radius: 6px;
  font-size: 16px;
  color: #000;
  padding-right: 40px;
  position: relative;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  box-sizing: border-box;
}
.selectObj p span:after {
  content: "";
  display: block;
  width: 20px;
  height: 19px;
  background: url("../images/open_arrow.svg") no-repeat 0 50%;
  position: absolute;
  top: 0;
  right: 0;
  background-size: 20px auto;
}
.selectObj p span.placeholder {
  color: #919191;
}
.selectObj p em {
  display: block;
  background-image: iconSvg("down", d9d9d9, 12, 9);
  width: 12px;
  height: 9px;
  position: absolute;
  top: 25px;
  right: 30px;
}
.selectObj p.open em {
  transform: rotate(180deg);
  top: 30px;
}
.selectObj ul {
  position: absolute;
  background-color: #fff;
  width: 100%;
  top: 50px;
  border-radius: 0 0 6px 6px;
  left: 0;
  padding: 10px 0;
  display: none;
  z-index: 3;
  box-sizing: border-box;
  border: 1px solid #000;
  border-top: none;
  max-height: 290px;
  overflow-y: auto;
}
.selectObj ul li {
  min-height: 35px;
  display: flex;
  padding: 5px 20px;
  color: #000;
  font-size: 16px;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.selectObj ul li:hover, .selectObj ul li.onHover {
  background-color: #000;
  color: #fff;
}
.selectObj ul li:last-child {
  border-bottom: none;
}
.selectObj p.open + ul {
  display: block;
}

.calcuExpGrid {
  height: calc(100vh - 68px);
  min-height: 800px;
}
.calcuExpGrid .calcInput {
  height: calc(100% - 0px);
}
.calcuExpGrid .calcInput > strong {
  font-size: 20px;
  font-weight: 500;
  color: #919191;
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.calcuExpGrid .calcInput .cbSpBox {
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap {
  width: 640px;
  position: relative;
  height: calc(100% - 100px);
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputData {
  height: calc(100% - 150px);
  overflow-y: auto;
  padding-right: 5px;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputData::-webkit-scrollbar {
  width: 4px;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputData::-webkit-scrollbar-thumb {
  background-color: #eee;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputData .inputObj {
  margin-bottom: 20px;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputData .inputObj label {
  height: 40px;
  line-height: 40px;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputData .inputObj > div p b, .calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputData .inputObj > div p input {
  color: #4E75FF;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn button {
  display: block;
  width: 100%;
  height: 44px;
  background-color: #000;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  border-radius: 6px;
  margin-top: 30px;
  cursor: pointer;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn button.reset, .calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn button.bookmark {
  background-color: #f5f5f5;
  border: 1px solid #000;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn button.reset span, .calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn button.bookmark span {
  background: url("../images/ic_reset.svg") no-repeat 0 50%;
  padding-left: 35px;
  color: #000;
  font-weight: 500;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn button.bookmark span {
  background-image: url("../images/Bookmark.svg");
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn button.bookmarkNone {
  background-color: #f9f9f9;
  border: 1px solid #fff;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn button.bookmarkNone span {
  background: url("../images/Bookmark.svg") no-repeat 0 50%;
  padding-left: 35px;
  color: #000;
  opacity: 0.2;
}
.calcuExpGrid .calcInput .cbSpBox .inputDataWrap .inputBtn .half {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
.calcuExpGrid .calcData {
  height: 100%;
}
.calcuExpGrid .calcData dl dt {
  height: 30px;
  line-height: 30px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}
.calcuExpGrid .calcData dl.calcResult dd.cbSpBox {
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.calcuExpGrid .calcData dl.calcResult dd.cbSpBox strong {
  color: #4e75ff;
  font-size: 40px;
  font-weight: 800;
}
.calcuExpGrid .calcData dl.calcResult dd.cbSpBox strong em {
  font-size: 34px;
  color: #000;
  font-weight: 800;
}
.calcuExpGrid .calcData dl.calcDetail {
  height: calc(100% - 322px);
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox {
  height: calc(100% - 40px);
  overflow: hidden;
  box-sizing: border-box;
  padding-bottom: 75px;
  position: relative;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .paidWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  flex-direction: column;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .paidWrap strong {
  font-size: 24px;
  line-height: 32px;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .paidWrap button {
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  height: 44px;
  width: 320px;
  font-size: 18px;
  margin-top: 30px;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap {
  box-sizing: border-box;
  height: calc(100% - 0px);
  padding-bottom: 35px;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap.noPaid {
  overflow-y: hidden;
  filter: blur(4px);
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap.noPaid:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  z-index: 2;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap div.summary {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  border-bottom: 1px solid #919191;
  padding: 0 40px;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap div.summary b {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #919191;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap div.summary ul li {
  font-size: 16px;
  font-weight: 700;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData {
  height: calc(100% - 0px);
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .swiper-button-prev:after, .calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .swiper-button-next:after {
  font-size: 18px;
  font-weight: 800;
  color: #000;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #000;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide {
  padding: 30px 55px 0 65px;
  box-sizing: border-box;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide b {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #919191;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide strong {
  color: #4E75FF;
  font-size: 24px;
  display: grid;
  align-items: start;
  gap: 20px;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 20px;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide ul {
  height: calc(100% - 110px);
  overflow-y: auto;
  padding-right: 10px;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide ul::-webkit-scrollbar {
  width: 4px;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide ul::-webkit-scrollbar-thumb {
  background-color: #eee;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide ul li {
  display: grid;
  align-items: start;
  gap: 20px;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 20px;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide ul li b {
  font-weight: 600;
  color: #000;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide ul li span {
  color: #4E75FF;
  font-size: 16px;
  font-weight: 600;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide ul li div p {
  color: #4E75FF;
  font-size: 16px;
  font-weight: 600;
  min-height: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}
.calcuExpGrid .calcData dl.calcDetail dd.cbSpBox .calcDetailWrap .calDetailData .calDetailSlide ul li div p:last-child {
  border-bottom: none;
}

.CalcuBookmark {
  height: calc(100vh - 128px);
  position: relative;
  box-sizing: border-box;
}
.CalcuBookmark.ovh {
  height: auto;
}
.CalcuBookmark .noData {
  height: calc(100% - 0px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.CalcuBookmark .noData a {
  display: block;
  height: 44px;
  border: 1px solid #000;
  background-color: #f5f5f5;
  border-radius: 6px;
  line-height: 44px;
  padding: 0 20px;
  font-size: 16px;
}
.CalcuBookmark .noData a span {
  display: block;
  background: url("../images/ic_noti.svg") no-repeat 100% 50%;
  padding-right: 20px;
}
.CalcuBookmark .CalcuWrap {
  height: calc(100% - 0px);
}
.CalcuBookmark .CalcuWrap .calcuList {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  height: calc(100% - 0px);
  overflow-x: auto;
  overflow-y: hidden;
}
.CalcuBookmark .CalcuWrap .calcuList::-webkit-scrollbar {
  height: 4px;
}
.CalcuBookmark .CalcuWrap .calcuList::-webkit-scrollbar-thumb {
  background-color: #eee;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox {
  width: 350px;
  flex: 0 0 auto;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont {
  box-sizing: border-box;
  padding: 20px 0 20px 15px;
  background-color: #fff;
  border-radius: 8px;
  height: calc(100% - 20px);
  border: 1px solid #919191;
  position: relative;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuTop {
  margin-right: 17px;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 50px;
  padding-bottom: 17px;
  font-size: 12px;
  position: relative;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuTop .delete {
  background: url("../images/ic_close_black.svg") no-repeat 50% 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: 0;
  cursor: pointer;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm {
  padding: 17px 6px 10px 0;
  margin: 0 5px 10px 0;
  height: calc(100% - 337px);
  overflow-y: scroll;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm::-webkit-scrollbar {
  width: 4px;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm dl dt {
  font-size: 16px;
  font-weight: 600;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm dl dd {
  font-size: 12px;
  padding: 15px 0 25px;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm dl dd .inputObj {
  margin-bottom: 0;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm dl dd .inputObj p b {
  font-size: 14px;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm .inputObj {
  position: relative;
  margin-bottom: 17px;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm .inputObj label {
  margin-bottom: 8px;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm .inputObj > div {
  position: relative;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm .inputObj > div p {
  border-color: #d3d3d4;
  min-height: 26px;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm .inputObj > div p b {
  width: calc(100% - 26px);
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuBtm .inputObj > div p button {
  width: 26px;
  height: 26px;
  background-size: 18px auto;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuApi {
  margin-right: 15px;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuButton {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuButton button {
  height: 40px;
  border-radius: 6px;
  background-color: #919191;
  color: #fff;
  font-size: 14px;
  width: 100%;
  font-weight: 800;
}
.CalcuBookmark .CalcuWrap .calcuList .calcuBox .calcuCont .calcuButton button.save {
  background-color: #4E75FF;
}
.CalcuBookmark .CalcuWrap .calcuAdd {
  position: absolute;
  width: 350px;
  box-sizing: border-box;
  border: 1px solid #919191;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 24px);
  top: 0;
  right: 0;
  background-color: #f5f5f5;
  cursor: pointer;
  font-size: 32px;
  color: #919191;
}
.CalcuBookmark .calcuApi {
  position: relative;
}
.CalcuBookmark .calcuApi label {
  font-weight: 600;
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}
.CalcuBookmark .calcuApi > button {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 16px;
  background: url("../images/ic_noti.svg") no-repeat 100% 50%;
  background-size: 8px auto;
}
.CalcuBookmark .calcuApi .DetailInfo {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #eee;
  border-radius: 8px;
  top: 20px;
  right: 0;
}
.CalcuBookmark .calcuApi .DetailInfo dl {
  padding: 16px;
}
.CalcuBookmark .calcuApi .DetailInfo dl dt {
  display: flex;
  align-items: center;
  justify-content: end;
}
.CalcuBookmark .calcuApi .DetailInfo dl dt .close {
  display: block;
  cursor: pointer;
  background: url("../images/ic_close_black.svg") no-repeat 0 0;
  width: 24px;
  height: 24px;
}
.CalcuBookmark .calcuApi .DetailInfo dl dd {
  max-height: 200px;
  overflow-y: scroll;
}
.CalcuBookmark .calcuApi .DetailInfo dl dd::-webkit-scrollbar {
  width: 4px;
}
.CalcuBookmark .calcuApi .DetailInfo dl dd::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.CalcuBookmark .calcuApi .DetailInfo dl dd table {
  border: 1px solid #e1e1e1;
  border-bottom: none;
}
.CalcuBookmark .calcuApi .DetailInfo dl dd table thead {
  border-bottom: 1px solid #919191;
  background-color: #f3f4f5;
}
.CalcuBookmark .calcuApi .DetailInfo dl dd table thead th {
  font-size: 13px;
  height: 28px;
  padding: 0 5px;
  text-align: left;
}
.CalcuBookmark .calcuApi .DetailInfo dl dd table tbody td {
  border-bottom: 1px solid #e1e1e1;
  font-size: 13px;
  padding: 8px 5px;
}
.CalcuBookmark .calcuApi .ApiResult {
  border-radius: 4px;
  background-color: #f5f5f5;
  border: 1px solid #d3d3d4;
  padding: 16px 0;
}
.CalcuBookmark .calcuApi .ApiResult .code {
  margin: 0 10px 0 20px;
  padding-right: 10px;
  height: 224px;
  overflow-y: scroll;
  word-break: break-word;
  font-size: 14px;
}
.CalcuBookmark .calcuApi .ApiResult .code::-webkit-scrollbar {
  width: 4px;
}
.CalcuBookmark .calcuApi .ApiResult .code::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.CalcuBookmark .bmDataBox {
  margin-bottom: 20px;
  padding: 30px 50px;
  position: relative;
}
.CalcuBookmark .bmDataBox .flag button, .CalcuBookmark .bmDataBox button.delete {
  width: 25px;
  height: 25px;
  background: url("../images/ic_noti.svg") no-repeat 50% 50%;
  position: absolute;
}
.CalcuBookmark .bmDataBox .flag button {
  top: 35px;
  left: 15px;
  transform: rotate(90deg);
}
.CalcuBookmark .bmDataBox .flag.open button {
  transform: rotate(270deg);
}
.CalcuBookmark .bmDataBox button.delete {
  background-image: url("../images/ic_close_black.svg");
  top: 15px;
  right: 15px;
}
.CalcuBookmark .bmDataBox .dataWrap .stepData p {
  font-size: 16px;
  font-weight: 600;
  min-height: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}
.CalcuBookmark .bmDataBox .dataWrap .stepData p:last-child {
  border-bottom: none;
}
.CalcuBookmark .bmDataBox .dataWrap .detailData {
  display: none;
  gap: 12px;
  grid-template-columns: auto 800px;
  margin: 60px 0 30px;
}
.CalcuBookmark .bmDataBox .dataWrap .detailData ul {
  width: 100%;
  margin-top: 25px;
}
.CalcuBookmark .bmDataBox .dataWrap .detailData ul li {
  display: grid;
  align-items: start;
  gap: 20px;
  grid-template-columns: 145px auto;
  margin-bottom: 14px;
}
.CalcuBookmark .bmDataBox .dataWrap .detailData ul li b {
  font-weight: 600;
  color: #000;
}
.CalcuBookmark .bmDataBox .dataWrap .detailData ul li span {
  color: #4E75FF;
  font-size: 16px;
  font-weight: 600;
}
.CalcuBookmark .bmDataBox .dataWrap .detailData ul li div p {
  color: #4E75FF;
  font-size: 16px;
  font-weight: 600;
  min-height: 24px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 0;
}
.CalcuBookmark .bmDataBox .dataWrap .detailData ul li div p:last-child {
  border-bottom: none;
}
.CalcuBookmark .bmDataBox .dataWrap .verCheck {
  text-align: right;
}
.CalcuBookmark .bmDataBox .dataWrap .verCheck button {
  height: 44px;
  border: 1px solid #000;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
}
.CalcuBookmark .bmDataBox .flag.open + .dataWrap .detailData {
  display: grid;
}

.cbSpBox.accountBox {
  height: calc(100vh - 130px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  min-height: 800px;
}
.cbSpBox.accountBox::-webkit-scrollbar {
  width: 4px;
}
.cbSpBox.accountBox::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.cbSpBox.accountBox .accountWrap {
  width: 500px;
  height: calc(100% - 0px);
}
.cbSpBox.accountBox .accountWrap.inCenter {
  height: auto;
}
.cbSpBox.accountBox .accountWrap.inCenter .accountInput {
  padding-top: 0;
  min-height: auto;
}
.cbSpBox.accountBox .accountWrap.inCenter .accountBtn {
  margin: 50px 0 0;
  padding-bottom: 0;
}
.cbSpBox.accountBox .requestWrap {
  width: 760px;
  height: calc(100% - 0px);
}
.cbSpBox.accountBox .accountInput {
  min-height: 470px;
  padding-top: 70px;
}
.cbSpBox.accountBox .accountInput .accInp {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 20px;
}
.cbSpBox.accountBox .accountInput .accInp > div {
  width: 100%;
}
.cbSpBox.accountBox .accountInput .accInp > div label {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.cbSpBox.accountBox .accountInput .accInp > div p {
  min-height: 56px;
  border: 1px solid #000;
  border-radius: 6px;
  overflow: hidden;
}
.cbSpBox.accountBox .accountInput .accInp > div p input {
  height: 56px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 500;
}
.cbSpBox.accountBox .accountInput .accInp > div p input:-webkit-autofill, .cbSpBox.accountBox .accountInput .accInp > div p input:-webkit-autofill:hover, .cbSpBox.accountBox .accountInput .accInp > div p input:-webkit-autofill:focus, .cbSpBox.accountBox .accountInput .accInp > div p input:-webkit-autofill:active {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.cbSpBox.accountBox .accountInput .accInp > div p textarea {
  width: calc(100% - 40px);
  margin: 20px;
  font-size: 16px;
  font-weight: 700;
  height: 100px;
  border: none;
  outline: none;
  box-sizing: border-box;
}
.cbSpBox.accountBox .accountInput .accInp > div p.error {
  border-color: #c00000;
  background: url("../images/ic_error.png") no-repeat 95% 50%;
}
.cbSpBox.accountBox .accountInput .accInp > div p.error input {
  padding-right: 60px;
}
.cbSpBox.accountBox .accountInput .accInp > div p.success {
  border-color: #4E75FF;
  background: url("../images/radio_on.svg") no-repeat 95% 50%;
  background-size: 40px;
}
.cbSpBox.accountBox .accountInput .accInp > div p.success input {
  padding-right: 60px;
}
.cbSpBox.accountBox .accountInput .accInp > div p.verifi {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: #4E75FF;
  gap: 20px;
  padding-right: 15px;
}
.cbSpBox.accountBox .accountInput .accInp > div p.verifi em {
  color: #4E75FF;
  font-weight: 700;
  font-size: 14px;
}
.cbSpBox.accountBox .accountInput .accInp > div p.verifi button {
  height: 34px;
  padding: 0 10px;
  background-color: #4E75FF;
  color: #fff;
  font-size: 14px;
  border-radius: 17px;
  white-space: nowrap;
}
.cbSpBox.accountBox .accountInput .accInp > div div:not(.selectObj) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.cbSpBox.accountBox .accountInput .accInp > div div:not(.selectObj) button {
  padding: 0 15px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  height: 56px;
  white-space: nowrap;
}
.cbSpBox.accountBox .accountInput .accInp > div div:not(.selectObj) p {
  width: 100%;
}
.cbSpBox.accountBox .accountInput .accInp > div span.errorText {
  color: #c00000;
  font-size: 16px;
  font-weight: 700;
  display: block;
  margin-top: 10px;
  text-align: right;
}
.cbSpBox.accountBox .accountInput .accSelect > label {
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.cbSpBox.accountBox .accountInput .accSelect ul {
  display: flex;
  align-items: start;
  justify-content: left;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 20px;
}
.cbSpBox.accountBox .accountInput .accSelect ul li {
  width: calc((100% - 80px) / 4);
  position: relative;
}
.cbSpBox.accountBox .accountInput .accSelect ul li input {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
}
.cbSpBox.accountBox .accountInput .accSelect ul li label {
  position: relative;
  display: flex;
  padding-left: 35px;
  min-height: 28px;
  align-items: center;
  font-size: 14px;
}
.cbSpBox.accountBox .accountInput .accSelect ul li label em {
  display: block;
  width: 28px;
  height: 28px;
  background: url("../images/radio_off.svg") no-repeat 50% 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 28px;
}
.cbSpBox.accountBox .accountInput .accSelect ul li input:checked + label em {
  background-image: url("../images/radio_on.svg");
}
.cbSpBox.accountBox .accountBtn {
  padding-bottom: 100px;
}
.cbSpBox.accountBox .accountBtn button {
  display: block;
  width: 100%;
  height: 44px;
  background-color: #000;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 6px;
  margin-top: 30px;
  cursor: pointer;
}
.cbSpBox.accountBox .accountBtn button.forgot {
  background-color: #f5f5f5;
  color: #000;
}
.cbSpBox.accountBox .accountBtn p {
  font-size: 16px;
  color: #919191;
  font-weight: 700;
  margin-top: 10px;
}
.cbSpBox.accountBox .accountBtn p a {
  color: #4E75FF;
}
.cbSpBox.accountBox .accountMsg {
  font-size: 16px;
  color: #919191;
  line-height: 24px;
  margin: 35px 0;
  font-weight: 400;
}
.cbSpBox.accountBox .accountMsg b {
  color: #4E75FF;
}
.cbSpBox.accountBox .accountMsg p.chkWrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: right;
}
.cbSpBox.accountBox .accountMsg p.chkWrap input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.cbSpBox.accountBox .accountMsg p.chkWrap label {
  display: block;
  background: url("../images/radio_off.svg") no-repeat 0 0;
  height: 30px;
  background-size: 30px auto;
  padding-left: 34px;
  color: #919191;
  line-height: 30px;
}
.cbSpBox.accountBox .accountMsg p.chkWrap input:checked + label {
  background-image: url("../images/radio_on.svg");
}
.cbSpBox.accountBox .accountMsg.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 35px;
}
.cbSpBox.accountBox .accountMsg.flex button {
  width: 240px;
  height: 44px;
  border: 1px solid #000;
  border-radius: 6px;
  background-color: #f5f5f5;
  padding: 0 25px;
  white-space: nowrap;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.dataExpInput {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: left;
  position: relative;
  gap: 20px;
}
.dataExpInput .inputObj {
  width: 240px;
}
.dataExpInput .inputObj.wide {
  width: 480px;
}
.dataExpInput .inputObj p {
  border-color: #919191;
  padding: 10px;
}

.dataExpStickyTable {
  height: calc(100% - 100px);
  margin-right: 5px;
  padding: 0 15px 0 20px;
  overflow-y: auto;
}
.dataExpStickyTable::-webkit-scrollbar {
  width: 4px;
}
.dataExpStickyTable::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.dataExpStickyTable table {
  width: 100%;
}
.dataExpStickyTable table thead th {
  text-align: left;
  height: 50px;
  position: sticky;
  top: 0;
  background-color: #fff;
}
.dataExpStickyTable table thead th div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
.dataExpStickyTable table thead th div span.orderby {
  display: block;
  width: 13px;
  height: 9px;
  background: url("../images/down.svg") no-repeat 0 0;
  cursor: pointer;
}
.dataExpStickyTable table thead th div span.orderby.desc {
  transform: rotate(-180deg);
}
.dataExpStickyTable table thead th div.right {
  justify-content: right;
}
.dataExpStickyTable table tbody tr td {
  background-color: #f5f5f5;
  border-bottom: 10px solid #fff;
}
.dataExpStickyTable table tbody tr td div {
  font-size: 16px;
  padding: 10px;
  font-weight: 700;
  min-height: 30px;
  display: flex;
  align-items: center;
}
.dataExpStickyTable table tbody tr td div.right {
  justify-content: end;
}
.dataExpStickyTable table tbody tr td div.center {
  justify-content: center;
}
.dataExpStickyTable table tbody tr td div.noData {
  width: 100%;
  height: calc(100vh - 310px);
  font-size: 24px;
  box-sizing: border-box;
  justify-content: center;
}
.dataExpStickyTable table tbody tr td div button.fav {
  width: 25px;
  height: 25px;
  background: url("../images/Bookmark.svg") no-repeat 50% 50%;
  display: block;
  cursor: pointer;
}
.dataExpStickyTable table tbody tr td div button.fav.on {
  background-image: url("../images/Bookmark_on.svg");
}
.dataExpStickyTable table tbody tr td div span.chkData {
  display: inline-block;
  border: 1px solid #000;
  background-color: #fff;
  border-radius: 4px;
  min-height: 32px;
  padding: 0 10px;
  line-height: 32px;
  white-space: nowrap;
}
.dataExpStickyTable table tbody tr td div span.chkData.on {
  background-color: transparent;
  border: none;
  line-height: normal;
  white-space: wrap;
}

.dataOverview > strong {
  font-size: 20px;
  font-weight: 700;
  color: #919191;
  display: block;
  margin-bottom: 20px;
}
.dataOverview .DataChart {
  display: flex;
  align-items: start;
  justify-content: center;
}
.dataOverview .ActiveChart {
  margin-top: -100px;
}
.dataOverview .DataOverviewGrid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 20px;
}
.dataOverview .DataOverviewGrid .span2 {
  grid-column: span 2;
}
.dataOverview .DataOverviewGrid .span3 {
  grid-column: span 3;
}
.dataOverview .DataOverviewGrid .span4 {
  grid-column: span 4;
}
.dataOverview .DataOverviewGrid .cbSpBox {
  padding: 20px 35px;
}
.dataOverview .DataOverviewGrid .cbSpBox .boxTitle {
  font-size: 24px;
  font-weight: 800;
}
.dataOverview .mapCont {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 232px;
}
.dataOverview .mapCont > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% / 3);
}
.dataOverview .mapCont > div p img {
  width: 45px;
}
.dataOverview .mapCont > div:nth-child(2) {
  margin-right: 25px;
}
.dataOverview .mapCont > div:nth-child(2) p img {
  width: 90px;
}
.dataOverview .mapCont > div dl {
  margin-left: 25px;
}
.dataOverview .mapCont > div dl dt {
  font-weight: 800;
  font-size: 24px;
}
.dataOverview .mapCont > div dl dd {
  font-size: 16px;
}
.dataOverview .overviewBox {
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.dataOverview .overviewBox dt {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  margin-bottom: 10px;
}
.dataOverview .overviewBox dd {
  font-size: 24px;
  text-align: center;
}

.DataBookMarkWrap .DataBookMarkList {
  background-color: #fff;
  border-radius: 16px;
  border: 1px solid #919191;
  margin-bottom: 20px;
  padding: 20px;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark {
  position: relative;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark .delete {
  background: url("../images/ic_close_black.svg") no-repeat 0 0;
  width: 20px;
  height: 20px;
  background-size: 100%;
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul {
  padding-top: 20px;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul li {
  display: flex;
  align-items: start;
  justify-content: left;
  margin-bottom: 7px;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul li label {
  display: block;
  font-size: 16px;
  width: 200px;
  position: relative;
  margin-right: 20px;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul li label:after {
  content: "";
  display: block;
  width: 1px;
  height: 12px;
  background-color: #d3d3d4;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -6px;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul li > div {
  width: calc(100% - 220px);
  display: flex;
  flex-wrap: wrap;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul li > div p {
  font-weight: 700;
  position: relative;
  padding-right: 20px;
  margin: 0 10px 10px 0;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul li > div p:after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background-color: #4E75FF;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul li > div p:last-child:after {
  display: none;
  padding-right: 0;
  margin-right: 0;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul li span.chkData {
  padding: 0 20px;
  height: 32px;
  line-height: 30px;
  background-color: #f5f5f5;
  border: 1px solid #d3d3d4;
  border-radius: 6px;
  font-size: 14px;
  box-sizing: border-box;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark ul li span.chkData.on {
  color: #4E75FF;
  border: none;
  border-bottom: 2px solid #4E75FF;
  background-color: transparent;
  border-radius: 0;
  font-weight: 700;
  font-size: 16px;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark .Result {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
  margin: 25px 0;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark .Result span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background-color: #fff;
  border: 1px solid #4E75FF;
  border-radius: 6px;
  height: 40px;
  color: #4E75FF;
  font-weight: 400;
  font-size: 16px;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark .Result span b {
  font-weight: 800;
  padding-left: 10px;
  display: inline-block;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark .Result span:nth-child(1) b {
  padding-left: 0;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark.History {
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #4E75FF;
  display: flex;
  background-color: #EEF2FF;
  gap: 20px;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark.History p.chkWrap {
  position: relative;
  display: flex;
  align-items: center;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark.History p.chkWrap input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark.History p.chkWrap em {
  display: block;
  background: url("../images/radio_off.svg") no-repeat 0 0;
  width: 40px;
  height: 40px;
  background-size: 100%;
}
.DataBookMarkWrap .DataBookMarkList .DataBookMark.History p.chkWrap input:checked + em {
  background-image: url("../images/radio_on.svg");
}
.DataBookMarkWrap .DataBookMarkList .addBook {
  margin: 20px 0 0;
  text-align: right;
}
.DataBookMarkWrap .DataBookMarkList .addBook button {
  cursor: pointer;
  width: 240px;
  height: 45px;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  border-radius: 6px;
  font-weight: 800;
}

.homeTopGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.homeTopGrid .cbSpBox {
  padding: 35px 40px;
}
.homeTopGrid .cbSpBox .boxTitle {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -0.36px;
  line-height: 32px;
  margin-bottom: 55px;
}
.homeTopGrid .cbSpBox .pressure {
  position: relative;
  height: 206px;
  margin-bottom: 40px;
}
.homeTopGrid .cbSpBox .pressure > div {
  position: absolute;
  right: 0;
  bottom: 0;
}
.homeTopGrid .cbSpBox .pressure > div b {
  display: block;
  margin: 10px;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
}
.homeTopGrid .cbSpBox .pressure > div.gover {
  width: 100%;
  height: 100%;
  background: rgba(78, 117, 255, 0.3);
  z-index: 1;
}
.homeTopGrid .cbSpBox .pressure > div.indu {
  width: 70%;
  height: 70%;
  background: rgba(78, 117, 255, 0.5);
  z-index: 2;
}
.homeTopGrid .cbSpBox .pressure > div.corp {
  width: 45%;
  height: 45%;
  background: #4e75ff;
  z-index: 3;
}
.homeTopGrid .cbSpBox .pressure p.arrow {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 19px;
  position: absolute;
  left: 0;
  bottom: 14px;
  z-index: 4;
}
.homeTopGrid .cbSpBox .pressure p.arrow b {
  background: url("../images/big_arrow2.png") no-repeat 100% 0;
  width: calc(100% - 110px);
  display: block;
  height: 19px;
  position: relative;
}
.homeTopGrid .cbSpBox .pressure p.arrow b:after {
  content: "";
  display: block;
  width: 90%;
  height: 2px;
  background-color: #fff;
  left: 0;
  top: 10px;
  position: absolute;
}
.homeTopGrid .cbSpBox .pressure p.arrow span {
  font-size: 15px;
  color: #fff;
  line-height: 19px;
  display: inline-block;
  font-weight: 700;
  text-align: right;
  white-space: nowrap;
}
.homeTopGrid .cbSpBox dl dt {
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 15px;
}
.homeTopGrid .cbSpBox dl dd {
  margin-bottom: 40px;
  line-height: 24px;
  font-size: 16px;
}

.homeBtmGrid {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}
.homeBtmGrid .cbSpBox {
  height: 200px;
  box-sizing: border-box;
  padding: 28px 30px;
}
.homeBtmGrid .cbSpBox a {
  display: block;
  width: 100%;
  height: calc(100% - 0px);
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  background: url("../images/ic_noti.svg") no-repeat 100% 100%;
}
.homeBtmGrid .cbSpBox > div {
  display: block;
  width: 100%;
  height: calc(100% - 0px);
  font-weight: 800;
  font-size: 24px;
}
.homeBtmGrid .cbSpBox > div span {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: calc(100% - 30px);
}
.homeBtmGrid .cbSpBox > div span img {
  width: 25%;
}

.cOverview > strong {
  font-size: 20px;
  display: block;
  color: #919191;
  font-weight: 600;
  margin-bottom: 20px;
}
.cOverview > .cbSpBox {
  padding: 45px 35px;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 20px;
  font-weight: 500;
}
.cOverview .cOverviewGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.cOverview .cOverviewGrid > .cbSpBox {
  padding: 25px 35px;
  box-sizing: border-box;
  min-height: 294px;
}
.cOverview .cOverviewGrid > .cbSpBox dl dt {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}
.cOverview .cOverviewGrid > .cbSpBox dl dt em {
  width: 52px;
  height: 52px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.cOverview .cOverviewGrid > .cbSpBox dl dt em.general {
  background-image: url("../images/overview_01.svg");
}
.cOverview .cOverviewGrid > .cbSpBox dl dt em.manufac {
  background-image: url("../images/overview_02.svg");
}
.cOverview .cOverviewGrid > .cbSpBox dl dt em.logist {
  background-image: url("../images/overview_03.svg");
}
.cOverview .cOverviewGrid > .cbSpBox dl dt em.hospital {
  background-image: url("../images/overview_04.svg");
}
.cOverview .cOverviewGrid > .cbSpBox dl dt em.ship {
  background-image: url("../images/overview_05.svg");
}
.cOverview .cOverviewGrid > .cbSpBox dl dd ul {
  margin: 10px 0 0 10px;
}
.cOverview .cOverviewGrid > .cbSpBox dl dd ul li {
  position: relative;
  line-height: 24px;
  padding-left: 12px;
}
.cOverview .cOverviewGrid > .cbSpBox dl dd ul li:after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}

.DSourceGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.DSourceGrid .cbSpBox {
  padding: 40px 35px;
}
.DSourceGrid .cbSpBox dt {
  height: 100px;
  text-indent: -999em;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.DSourceGrid .cbSpBox dt.beis {
  background-image: url("../images/logo_BEIS.png");
  background-size: 144px auto;
}
.DSourceGrid .cbSpBox dt.epa {
  background-image: url("../images/logo_epa.png");
  background-size: auto 30px;
}
.DSourceGrid .cbSpBox dt.moe_kor {
  background-image: url("../images/logo_MOE(KOR).png");
  background-size: auto 40px;
}
.DSourceGrid .cbSpBox dt.moe_jp {
  background-image: url("../images/logo_MOE(JAP).png");
  background-size: auto 56px;
}
.DSourceGrid .cbSpBox dt.diser {
  background-image: url("../images/logo_DISER.png");
  background-size: 126px auto;
}
.DSourceGrid .cbSpBox dt.mfe {
  background-image: url("../images/logo_MFE.png");
  background-size: 157px auto;
}
.DSourceGrid .cbSpBox dt.tgo {
  background-image: url("../images/logo_TGO.png");
  background-size: auto 55px;
}
.DSourceGrid .cbSpBox dt.exiobase {
  background-image: url("../images/logo_ExioBase.png");
  background-size: auto 31px;
}
.DSourceGrid .cbSpBox dt.glec {
  background-image: url("../images/logo_GLEC.png");
  background-size: auto 67px;
}
.DSourceGrid .cbSpBox dt.ipcc {
  background-image: url("../images/logo_IPCC.png");
  background-size: auto 63px;
}
.DSourceGrid .cbSpBox dd {
  font-size: 12px;
  line-height: 16px;
}

.priceWrap {
  height: calc(100vh - 130px);
  min-height: 800px;
}
.priceWrap .priceGrid {
  height: calc(100% - 0px);
}
.priceWrap .priceGrid .cbSpBox {
  height: calc(100% - 0px);
  padding: 50px;
  box-sizing: border-box;
  overflow-y: auto;
}
.priceWrap .priceGrid .cbSpBox::-webkit-scrollbar {
  width: 4px;
}
.priceWrap .priceGrid .cbSpBox::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.priceWrap .priceGrid .cbSpBox .priceList {
  display: grid;
  grid-template-columns: calc(100% - 1050px) repeat(3, 320px);
  gap: 30px;
  border-bottom: 1px solid #919191;
  min-height: 88px;
}
.priceWrap .priceGrid .cbSpBox .priceList > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.priceWrap .priceGrid .cbSpBox .priceList > div:first-child {
  justify-content: left;
  font-weight: 600;
}
.priceWrap .priceGrid .cbSpBox .priceList > div:last-child {
  background-color: #f5f5f5;
}
.priceWrap .priceGrid .cbSpBox .priceList.btn {
  border-bottom: none;
  font-size: 20px;
  font-weight: 600;
  padding-top: 25px;
}
.priceWrap .priceGrid .cbSpBox .priceList.btn > div:last-child {
  background: none;
}
.priceWrap .priceGrid .cbSpBox .priceList.btn button {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}
.priceWrap .priceGrid .cbSpBox .priceList.priceData dl {
  width: 100%;
}
.priceWrap .priceGrid .cbSpBox .priceList.priceData dl dt {
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.priceWrap .priceGrid .cbSpBox .priceList.priceData dl dd {
  height: 182px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.priceWrap .priceGrid .cbSpBox .priceList.priceData dl dd em {
  display: block;
  width: 100%;
  text-align: center;
  bottom: 20px;
  left: 0;
  position: absolute;
  color: #4E75FF;
  font-size: 16px;
  font-weight: 700;
}
.priceWrap .priceGrid .cbSpBox .priceList.priceData dl dd b {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.priceWrap .priceGrid .cbSpBox .priceList.priceData dl dd strong {
  font-size: 40px;
  font-weight: 800;
}
.priceWrap .priceGrid .cbSpBox .priceList.priceData > div:last-child dl dt {
  background-color: #4E75FF;
  color: #fff;
}
.priceWrap .priceGrid .cbSpBox .priceList.priceData > div:last-child dl dd strong {
  color: #4E75FF;
}
.priceWrap .contactus {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
}
.priceWrap .contactus a {
  color: #4E75FF;
  font-weight: 800;
  margin-left: 10px;
}

.accountDetail {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  height: calc(100vh - 130px);
  min-height: 800px;
}
.accountDetail > div .cbSpBox {
  padding: 20px 35px;
  box-sizing: border-box;
}
.accountDetail > div .cbSpBox .boxTitle {
  font-size: 24px;
  font-weight: 800;
  height: 43px;
  line-height: 43px;
}
.accountDetail > div .cbSpBox .boxCont {
  height: calc(100% - 50px);
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 80px;
}
.accountDetail > div .cbSpBox .boxCont button {
  height: 56px;
  border-radius: 6px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}
.accountDetail > div .cbSpBox .boxCont .noData {
  background-color: #f9f9f9;
  text-align: center;
  height: calc(100% - 80px);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
}
.accountDetail > div.left .cbSpBox {
  height: calc(100% - 0px);
}
.accountDetail > div.left .cbSpBox .boxCont ul {
  border-top: 1px solid #919191;
  margin-bottom: 70px;
}
.accountDetail > div.left .cbSpBox .boxCont ul li {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #919191;
  font-size: 20px;
  gap: 20px;
}
.accountDetail > div.left .cbSpBox .boxCont ul li > span {
  color: #919191;
}
.accountDetail > div.left .cbSpBox .boxCont ul li input {
  width: 80%;
  height: 56px;
  border: 1px solid #000;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 20px;
  font-size: 16px;
  color: #000;
  font-weight: 700;
}
.accountDetail > div.left .cbSpBox .boxCont ul li input:disabled {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #919191;
}
.accountDetail > div.left .cbSpBox .boxCont ul li .selectObj {
  width: 80%;
}
.accountDetail > div.left .cbSpBox .boxCont .insBtn {
  padding: 0 20px;
  background-color: #fff;
  border: 1px solid #4E75FF;
  color: #4E75FF;
  cursor: pointer;
}
.accountDetail > div.right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.accountDetail > div.right .cbSpBox {
  height: calc(100% / 2);
}
.accountDetail > div.right .cbSpBox .boxCont p {
  text-align: center;
  font-size: 20px;
}
.accountDetail > div.right .cbSpBox .boxCont div.key {
  border-radius: 6px;
  border: 1px solid #000;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0 35px;
}
.accountDetail > div.right .cbSpBox .boxCont div.date {
  font-size: 20px;
  text-align: center;
  color: #919191;
}
.accountDetail > div.right .cbSpBox .boxCont div.date b {
  color: #000;
  font-weight: 700;
}
.accountDetail > div.right .cbSpBox .boxCont ul {
  margin-bottom: 30px;
}
.accountDetail > div.right .cbSpBox .boxCont ul li {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
}
.accountDetail > div.right .cbSpBox .boxCont ul li span {
  color: #919191;
}

.MuiIconButton-root.dialogClose {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0;
}

.dialogCont .dialogText {
  padding: 20px 0 40px;
}
.dialogCont .dialogBtn {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
.dialogCont .dialogBtn > button {
  cursor: pointer;
  width: 100%;
  background-color: #4E75FF;
  color: #fff;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  height: 40px;
}
.dialogCont .dialogBtn > button.cancel {
  background-color: #000;
}
.dialogCont .accountInput {
  margin: 30px 0 50px;
  width: 450px;
}
.dialogCont .accountInput .accInp {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 20px;
}
.dialogCont .accountInput .accInp > div {
  width: 100%;
}
.dialogCont .accountInput .accInp > div label {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 16px;
  color: #919191;
  font-weight: 700;
}
.dialogCont .accountInput .accInp > div p {
  min-height: 50px;
  border: 1px solid #000;
  border-radius: 6px;
  overflow: hidden;
}
.dialogCont .accountInput .accInp > div p input {
  height: 50px;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 700;
}
.dialogCont .accountInput .accInp > div span.errorText {
  color: #c00000;
  font-size: 16px;
  font-weight: 700;
  display: block;
  margin-top: 10px;
  text-align: right;
}

.policyContWrap {
  padding: 50px 40px 50px 50px;
  height: calc(100% - 0px);
  box-sizing: border-box;
}
.policyContWrap .policyCont {
  height: calc(100% - 0px);
  box-sizing: border-box;
  overflow-y: auto;
  padding-right: 10px;
  line-height: 24px;
}
.policyContWrap .policyCont::-webkit-scrollbar {
  width: 4px;
}
.policyContWrap .policyCont::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.policyContWrap .policyCont.kor {
  display: none;
}
.policyContWrap .policyCont.kor > strong {
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin: 15px 0 0;
}
.policyContWrap .policyCont.kor > b {
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin: 15px 0;
}
.policyContWrap .policyCont.kor ol {
  margin: 15px 0 0 20px;
}
.policyContWrap .policyCont.kor ol > li {
  list-style: decimal;
}
.policyContWrap .policyCont.kor ol > li > ul {
  margin: 10px 0;
}
.policyContWrap .policyCont.kor ol > li > ul > li ul.dotList {
  margin: 10px 0 10px 20px;
}
.policyContWrap .policyCont.kor ol > li > ul > li ul.dotList li {
  list-style: inside;
  margin-left: 20px;
}
.policyContWrap .policyCont.kor ol > li > ul.alphaList {
  margin: 10px 0 10px 20px;
}
.policyContWrap .policyCont.kor ol > li > ul.alphaList > li {
  list-style: upper-alpha;
}
.policyContWrap .policyCont.kor ol > li > ul.romanList {
  margin: 10px 0 10px 20px;
}
.policyContWrap .policyCont.kor ol > li > ul.romanList > li {
  list-style: upper-roman;
}
.policyContWrap .policyCont.eng ol {
  counter-reset: fol;
}
.policyContWrap .policyCont.eng ol > li {
  padding-left: 30px;
  position: relative;
  counter-increment: fol;
  margin-bottom: 20px;
}
.policyContWrap .policyCont.eng ol > li:before {
  content: counter(fol) ".";
  font-size: 20px;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
}
.policyContWrap .policyCont.eng ol > li > b {
  font-size: 20px;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
}
.policyContWrap .policyCont.eng ol > li > ol {
  counter-reset: sol;
}
.policyContWrap .policyCont.eng ol > li > ol > li {
  position: relative;
  counter-increment: sol;
}
.policyContWrap .policyCont.eng ol > li > ol > li > b {
  font-size: 16px;
  font-weight: 600;
  display: block;
}
.policyContWrap .policyCont.eng ol > li > ol > li:before {
  content: counter(fol) "." counter(sol) ".";
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
}
.policyContWrap .policyCont.eng ol > li ul > li {
  list-style: inside;
}

.LoadingImg {
  background: url("../images/loading.svg") no-repeat 50% 50%;
  background-size: 100%;
  width: 80px;
  height: 80px;
  animation: LoadingSpin 2s linear infinite;
}

.findKeyword {
  padding: 20px;
  margin-bottom: 10px;
}
.findKeyword > div {
  border: 1px solid #919191;
  border-radius: 6px;
  padding-left: 36px;
  background: url("../images/bi_search_gray.svg") no-repeat 11px 50%;
  height: 44px;
}
.findKeyword > div input {
  width: calc(100% - 72px);
  height: 44px;
  font-weight: 500;
  font-size: 16px;
}

.findGrid {
  display: grid;
  grid-template-columns: 280px calc(100% - 280px);
  height: calc(100vh - 226px);
}
.findGrid dl.filter {
  padding: 0 10px 0 20px;
  position: relative;
  height: 100%;
}
.findGrid dl.filter dt {
  font-weight: 700;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
}
.findGrid dl.filter dd {
  height: calc(100% - 120px);
  overflow-y: auto;
  padding-right: 6px;
}
.findGrid dl.filter dd::-webkit-scrollbar {
  width: 4px;
}
.findGrid dl.filter dd::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
}
.findGrid dl.filter dd .selectObj {
  margin-bottom: 20px;
}
.findGrid dl.filter dd .ResetBtn {
  width: 100%;
  position: absolute;
  left: 20px;
  bottom: 20px;
}
.findGrid dl.filter dd .ResetBtn button {
  display: block;
  width: calc(100% - 40px);
  height: 44px;
  background-color: #f5f5f5;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #000;
}
.findGrid dl.filter dd .ResetBtn button span {
  background: url("../images/ic_reset.svg") no-repeat 0 50%;
  padding-left: 35px;
}
.findGrid .dataExpStickyTable {
  height: calc(100% - 0px);
}

.welcomMsg {
  text-align: center;
  padding: 20px 0 100px;
}
.welcomMsg b {
  font-size: 24px;
  color: #919191;
  display: block;
}
.welcomMsg strong {
  font-size: 40px;
  line-height: 40px;
  display: block;
  margin-top: 25px;
  letter-spacing: -0.6px;
}

.homeWrap > dl {
  margin-bottom: 120px;
}
.homeWrap > dl:last-child {
  margin-bottom: 0;
}
.homeWrap > dl > dt {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
  letter-spacing: -0.6px;
}
.homeWrap > dl.solution dd {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.homeWrap > dl.solution dd .cbSpBox {
  padding: 60px 28px;
}
.homeWrap > dl.solution dd .cbSpBox > div {
  background: url("../images/home_01.svg") no-repeat 50% 0;
  padding-top: 84px;
  font-size: 22px;
  text-align: center;
  word-break: break-word;
}
.homeWrap > dl.solution dd .cbSpBox:nth-child(2) > div {
  background-image: url("../images/home_02.svg");
}
.homeWrap > dl.solution dd .cbSpBox:nth-child(3) > div {
  background-image: url("../images/home_03.svg");
}
.homeWrap > dl.calculator > dd {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, 1fr);
}
.homeWrap > dl.calculator > dd > .cbSpBox {
  padding: 25px 35px;
  box-sizing: border-box;
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dt {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 20px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dt em {
  width: 52px;
  height: 52px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dt em.general {
  background-image: url("../images/overview_01.svg");
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dt em.manufac {
  background-image: url("../images/overview_02.svg");
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dt em.logist {
  background-image: url("../images/overview_03.svg");
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dt em.hospital {
  background-image: url("../images/overview_04.svg");
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dt em.ship {
  background-image: url("../images/overview_05.svg");
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dd ul {
  margin: 10px 0 0 10px;
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dd ul li {
  position: relative;
  line-height: 24px;
  padding-left: 12px;
}
.homeWrap > dl.calculator > dd > .cbSpBox dl dd ul li:after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 0;
}
.homeWrap > dl.overview .chartGrid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 20px;
}
.homeWrap > dl.overview .chartGrid .basicChart .cbSpBox {
  padding: 30px 30px 0;
}
.homeWrap > dl.overview .chartGrid .basicChart .cbSpBox:first-child {
  margin-bottom: 20px;
}
.homeWrap > dl.overview .chartGrid .basicChart .cbSpBox > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeWrap > dl.overview .chartGrid .basicChart .cbSpBox strong {
  display: block;
  font-size: 24px;
}
.homeWrap > dl.overview .chartGrid .worldchart .cbSpBox {
  height: 100%;
  overflow: hidden;
  padding: 30px 30px 0;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.homeWrap > dl.overview .chartGrid .worldchart .cbSpBox > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 50px);
}
.homeWrap > dl.overview .chartGrid .worldchart .cbSpBox strong {
  display: block;
  font-size: 24px;
}
.homeWrap > dl.overview .swiperLogo {
  padding: 30px;
  margin-bottom: 20px;
}
.homeWrap > dl.overview .swiperLogo strong {
  display: block;
  font-size: 24px;
  margin-bottom: 30px;
}
.homeWrap > dl.overview .swiperLogo .swiper {
  height: 70px;
}
.homeWrap > dl.overview .swiperLogo .swiper .swiper-slide {
  width: auto;
}
.homeWrap > dl.overview .swiperLogo .swiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeWrap > dl.overview .cOverviewGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.homeWrap > dl.overview .cOverviewGrid > .cbSpBox {
  padding: 25px 35px;
  box-sizing: border-box;
  min-height: 264px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 20px;
}
.homeWrap > dl.iso .cbSpBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 200px;
}
.homeWrap > dl.iso .cbSpBox img {
  height: 117px;
}

.integrateWrap > strong {
  display: block;
  color: #919191;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}
.integrateWrap .integrate {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  height: calc(100vh - 162px);
}
.integrateWrap .integrate .cbSpBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.integrateWrap .integrate .cbSpBox strong {
  display: block;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.48px;
  margin-bottom: 50px;
  padding-top: 100px;
  background: url("../images/lnb/product.svg") no-repeat 50% 0;
  background-size: 86px auto;
}
.integrateWrap .integrate .cbSpBox button {
  display: block;
  cursor: pointer;
  width: 320px;
  height: 44px;
  border-radius: 6px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.integrateWrap .integrate .cbSpBox .data strong {
  background-image: url("../images/lnb/search.svg");
}

/** MEDIA **/
@keyframes LoadingSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 1920px) {
  .calcuExpGrid .calcInput > strong {
    font-size: 16px;
  }
  .calcuExpGrid .calcData {
    height: 100%;
  }
  .calcuExpGrid .calcData dl dt {
    font-size: 18px;
  }

  .DSourceGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .DSourceGrid .cbSpBox {
    padding: 40px 35px;
  }
  .DSourceGrid .cbSpBox dt.moe_kor, .DSourceGrid .cbSpBox dt.tgo, .DSourceGrid .cbSpBox dt.exiobase {
    background-size: 100% auto;
  }

  .accountDetail > div .cbSpBox .boxCont {
    padding: 0 40px;
  }
  .accountDetail > div.left .cbSpBox .boxCont ul li, .accountDetail > div.right .cbSpBox .boxCont ul li {
    font-size: 18px;
  }
  .accountDetail > div.left .cbSpBox .boxCont ul li .selectObj, .accountDetail > div.left .cbSpBox .boxCont ul li input, .accountDetail > div.right .cbSpBox .boxCont ul li .selectObj, .accountDetail > div.right .cbSpBox .boxCont ul li input {
    width: 60%;
  }
  .accountDetail > div.left .cbSpBox .boxCont p, .accountDetail > div.left .cbSpBox .boxCont div.key, .accountDetail > div.left .cbSpBox .boxCont div.date, .accountDetail > div.right .cbSpBox .boxCont p, .accountDetail > div.right .cbSpBox .boxCont div.key, .accountDetail > div.right .cbSpBox .boxCont div.date {
    font-size: 18px;
  }
}
@media (max-width: 1700px) {
  .dataOverview .DataChart {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .priceWrap .priceGrid .cbSpBox dl dd ul li {
    font-size: 18px;
  }
  .priceWrap .priceGrid .cbSpBox dl dd button {
    width: 80%;
    left: 10%;
  }
}
@media (max-width: 1440px) {
  .DSourceGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .DSourceGrid .cbSpBox {
    padding: 40px 35px;
  }
  .DSourceGrid .cbSpBox dt.mfe {
    background-size: 100% auto;
  }
}
/* 한글버전 */
body.kr .homeTopGrid .cbSpBox dl dt {
  font-weight: 700;
  font-size: 18px;
}
body.kr .homeTopGrid .cbSpBox dl dd {
  color: #919191;
}
body.kr .dataOverview .overviewBox dd {
  font-size: 20px;
}
body.kr .cOverview > .cbSpBox {
  font-weight: 700;
  font-size: 18px;
}
body.kr .policyContWrap .policyCont.kor {
  display: block;
}
body.kr .policyContWrap .policyCont.eng {
  display: none;
}
